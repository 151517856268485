import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/frankfurt_school/frankfurt.png"
import frankfurt1 from "../../../assets/images/portfolio_items/frankfurt_school/frankfurt1.png"
import frankfurt2 from "../../../assets/images/portfolio_items/frankfurt_school/frankfurt2.png"
import frankfurt3 from "../../../assets/images/portfolio_items/frankfurt_school/frankfurt3.png"
import frankfurt4 from "../../../assets/images/portfolio_items/frankfurt_school/frankfurt4.png"
import frankfurt5 from "../../../assets/images/portfolio_items/frankfurt_school/frankfurt5.png"
import frankfurt6 from "../../../assets/images/portfolio_items/frankfurt_school/frankfurt6.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | Frankfurt School - Magnolia Website'} 
    description="Discover more about the Frankfurt School's new Magnolia Website project: give a look at ICON's portfolio now!"
    canonical={'https://icon-worldwide.com/works/frankfurt-school'} />
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Frankfurt School of Finance and Management, ICON Worldwide, magnolia CMS website portfolio" title="ICON Worldwide portfolio, magnolia CMS, Frankfurt School"/>
            <div className="title"><h1>Frankfurt School<span></span></h1>
            <h2>Magnolia CMS development project</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>The Frankfurt School of Finance and Management is one of the leading business schools in Germany.
        As digital agency of record, ICON has developed multiple website and platforms and maintains the Magnolia CMS development.</p>
        <div className="portfolio-images">
            <img src={frankfurt1} alt="Frankfurt School of Finance and Management, ICON Worldwide, magnolia CMS website portfolio" title="ICON Worldwide portfolio, magnolia CMS, Frankfurt School"/>
            <img src={frankfurt4} alt="Frankfurt School of Finance and Management, ICON Worldwide, magnolia CMS website portfolio" title="ICON Worldwide portfolio, magnolia CMS, Frankfurt School"/>
            <img src={frankfurt6} alt="Frankfurt School of Finance and Management, ICON Worldwide, magnolia CMS website portfolio" title="ICON Worldwide portfolio, magnolia CMS, Frankfurt School"/>
            <img src={frankfurt2} alt="Frankfurt School of Finance and Management, ICON Worldwide, magnolia CMS website portfolio" title="ICON Worldwide portfolio, magnolia CMS, Frankfurt School"/>
            <img src={frankfurt3} alt="Frankfurt School of Finance and Management, ICON Worldwide, magnolia CMS website portfolio" title="ICON Worldwide portfolio, magnolia CMS, Frankfurt School"/>
            <img src={frankfurt5} alt="Frankfurt School of Finance and Management, ICON Worldwide, magnolia CMS website portfolio" title="ICON Worldwide portfolio, magnolia CMS, Frankfurt School"/>
        </div>
        <WorkFooter previous="iron-deficiency-day" next="actelion"/>
        </div>
    </div>
</div>